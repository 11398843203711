<template>
  <component
    :is="blockLevel ? `div` : 'span'"
    ref="ctxRef"
    class="transform-gpu"
    :class="[isMounted ? 'opacity-100' : 'opacity-0']"
  >
    <span ref="textRef">
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrambleTextPlugin } from 'gsap/ScrambleTextPlugin'

interface HeadingProps {
  blockLevel?: boolean
  animation?: 'scramble' | 'slash'
  delay?: number
  animate?: boolean
}

const props = withDefaults(defineProps<HeadingProps>(), {
  blockLevel: false,
  animation: 'scramble',
  delay: 0,
  animate: true
})

const ctxRef = ref<HTMLElement | null>(null)
const textRef = ref<HTMLElement | null>(null)

const split = ref<null | any>(null)
const tween = ref<null | any>(null)

const isMounted = ref(false)

const { stop } = useIntersectionObserver(
  ctxRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting && props.animate) {
      tween.value?.play()
    }
  },
  {
    threshold: 0.1
  }
)

watch(
  () => props.animate,
  (_, oldValue) => {
    if (!oldValue) {
      tween.value?.play()
    }
  }
)

let ctx: any = null

onMounted(() => {
  gsap.registerPlugin(SplitText)
  gsap.registerPlugin(ScrambleTextPlugin)
  isMounted.value = true

  if (ctxRef.value) {
    ctx = gsap.context(() => {
      split.value = new SplitText(textRef.value, {
        type: 'chars, words'
      })

      const childSplit = new SplitText(textRef.value, {
        type: 'chars, words'
      })

      gsap.set(childSplit.words, { perspective: 900 })

      tween.value = gsap.from(childSplit.words, {
        autoAlpha: 0,
        stagger: {
          each: 0.02
        },
        scrambleText: {
          text: '{original}',
          chars: 'SPACEKART'
        },
        paused: true
      })
    }, ctxRef.value)
  }
})

onUnmounted(() => {
  if (ctx) {
    ctx.revert()
  }
  stop()
})
</script>
